// src/pages/AdminPricingPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api';

const AdminPricingPage = () => {
    const { itemId } = useParams();
    const [item, setItem] = useState(null);
    const [pricingTiers, setPricingTiers] = useState([]);
    const [newTier, setNewTier] = useState({
        minNights: '',
        maxNights: '',
        pricePerNight: '',
    });

    useEffect(() => {
        const fetchItemAndPricing = async () => {
            const itemResponse = await api.get(`/items/${itemId}`);
            setItem(itemResponse.data);

            const pricingResponse = await api.get(`/admin/pricing/item/${itemId}`);
            setPricingTiers(pricingResponse.data);
        };
        fetchItemAndPricing();
    }, [itemId]);

    const handleInputChange = (e) => {
        setNewTier({
            ...newTier,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddPricingTier = async () => {
        const newTierData = {
            minNights: parseInt(newTier.minNights, 10),
            maxNights: newTier.maxNights ? parseInt(newTier.maxNights, 10) : null,
            pricePerNight: parseFloat(newTier.pricePerNight),
        };

        await api.post(`/admin/pricing/item/${itemId}`, newTierData);
        // Refresh the pricing tiers
        const response = await api.get(`/admin/pricing/item/${itemId}`);
        setPricingTiers(response.data);
        // Reset the form
        setNewTier({
            minNights: '',
            maxNights: '',
            pricePerNight: '',
        });
    };

    const handleDeletePricingTier = async (tierId) => {
        await api.delete(`/admin/pricing/${tierId}`);
        // Refresh the pricing tiers
        const response = await api.get(`/admin/pricing/item/${itemId}`);
        setPricingTiers(response.data);
    };

    return (
        <div>
            <h1>Manage Pricing for {item?.description}</h1>
            <h2>Existing Pricing Tiers</h2>
            <table>
                <thead>
                <tr>
                    <th>Min Nights</th>
                    <th>Max Nights</th>
                    <th>Price Per Night</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {pricingTiers.map((tier) => (
                    <tr key={tier.id}>
                        <td>{tier.minNights}</td>
                        <td>{tier.maxNights || 'No Max'}</td>
                        <td>${tier.pricePerNight.toFixed(2)}</td>
                        <td>
                            {/* Add edit functionality if needed */}
                            <button onClick={() => handleDeletePricingTier(tier.id)}>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <h2>Add New Pricing Tier</h2>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleAddPricingTier();
                }}
            >
                <label>
                    Min Nights:
                    <input
                        type="number"
                        name="minNights"
                        value={newTier.minNights}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                <br />
                <label>
                    Max Nights:
                    <input
                        type="number"
                        name="maxNights"
                        value={newTier.maxNights}
                        onChange={handleInputChange}
                    />
                </label>
                <br />
                <label>
                    Price Per Night:
                    <input
                        type="number"
                        step="0.01"
                        name="pricePerNight"
                        value={newTier.pricePerNight}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                <br />
                <button type="submit">Add Pricing Tier</button>
            </form>
        </div>
    );
};

export default AdminPricingPage;