import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getAllAudioEquipments = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const getAudioEquipmentById = async (id) => {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
};

export const addAudioEquipment = async (audioEquipment) => {
    const response = await axios.post(API_URL, audioEquipment);
    return response.data;
};

export const updateAudioEquipment = async (id, audioEquipment) => {
    const response = await axios.put(`${API_URL}/${id}`, audioEquipment);
    return response.data;
};

export const deleteAudioEquipment = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};

export const getAvailableAudioEquipments = async () => {
    const response = await axios.get(`${API_URL}/available`);
    return response.data;
};
