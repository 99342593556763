// src/pages/AdminItemsPage.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';

const AdminItemsPage = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchAllItems = async () => {
            const response = await api.get('/items/all');
            setItems(response.data);
        };
        fetchAllItems();
    }, []);

    return (
        <div>
            <h1>Admin - Items</h1>
            <ul>
                {items.map((item) => (
                    <li key={item.itemId}>
                        {item.description} -{' '}
                        <Link to={`/admin/items/${item.itemId}/pricing`}>Manage Pricing</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminItemsPage;