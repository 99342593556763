// src/components/ItemCard.js
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'; // Import Link
import './ItemCard.css';

const ItemCard = ({ item }) => {
    console.log("Rendering item:", item); // Debugging log

    return (
        <Link to={`/items/${item.itemId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="item-card">
                {item.imageUrl ? (
                    <img src={item.imageUrl} alt={item.description} className="item-card-image" />
                ) : (
                    <div className="item-card-placeholder">No Image Available</div>
                )}
                <div className="item-card-content">
                    <h3 className="item-card-title">{item.description}</h3>
                    <p className="item-card-sku">SKU: {item.customSku}</p>
                    <p className="item-card-price">${item.price.toFixed(2)} /per night</p>
                    <p className="item-card-quantity">Available: {item.quantityOnHand}</p>
                </div>
            </div>
        </Link>
    );
};

ItemCard.propTypes = {
    item: PropTypes.shape({
        itemId: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        customSku: PropTypes.string,
        price: PropTypes.number.isRequired,
        quantityOnHand: PropTypes.number.isRequired,
        imageUrl: PropTypes.string, // Optional
    }).isRequired,
};

export default ItemCard;