// src/pages/LensPage.js
import React, { useEffect, useState } from 'react';
import ItemCard from '../components/ItemCard';
import api from '../api';

const LensPage = () => {
    const [lensForCanon, setLensForCanon] = useState([]);
    const [lensForSony, setLensForSony] = useState([]);
    const [lensForNikon, setLensForNikon] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLensesForCanon = async () => {
            try {
                const response = await api.get('/items/category/233');
                console.log("Type of response.data:", typeof response.data);
                console.log("Fetched Lenses for Canon:", response.data);
                setLensForCanon(response.data);
            } catch (error) {
                setError('Failed to fetch Lenses for Canon');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchLensesForCanon();

        const fetchLensesForSony = async () => {
            try {
                const response = await api.get('/items/category/234');
                console.log("Type of response.data:", typeof response.data);
                console.log("Fetched Lenses for Sony:", response.data);
                setLensForSony(response.data);
            } catch (error) {
                setError('Failed to fetch Lenses for Sony');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchLensesForSony();

        const fetchLensesForNikon = async () => {
            try {
                const response = await api.get('/items/category/235');
                console.log("Type of response.data:", typeof response.data);
                console.log("Fetched Lenses for Nikon:", response.data);
                setLensForNikon(response.data);
            } catch (error) {
                setError('Failed to fetch Lenses for Nikon');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchLensesForNikon();

    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h1>Lenses for Canon</h1>
            <div className="items-grid">
                {lensForCanon.map(item => (
                    <ItemCard key={item.itemId} item={item}/>
                ))}
            </div>
            <h1>Lenses for Sony</h1>
            <div className="items-grid">
                {lensForSony.map(item => (
                    <ItemCard key={item.itemId} item={item}/>
                ))}
            </div>
            <h1>Lenses for Nikon</h1>
            <div className="items-grid">
                {lensForNikon.map(item => (
                    <ItemCard key={item.itemId} item={item}/>
                ))}
            </div>
        </div>
    );
};

export default LensPage;
