// src/pages/AccessoryPage.js
import React, { useEffect, useState } from 'react';
import ItemCard from '../components/ItemCard';
import api from '../api';

const AccessoryPage = () => {
    const [videoProductionItems, setVideoProductionItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVideoProductionAccessoriesItems = async () => {
            try {
                const response = await api.get('/items/category/243');
                console.log("Type of response.data:", typeof response.data);
                console.log("Fetched Video Production and Accessories Items:", response.data);
                setVideoProductionItems(response.data);
            } catch (error) {
                setError('Failed to fetch Video Production and Accessories items');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchVideoProductionAccessoriesItems();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h1>Projectors and Accessories</h1>
            <div className="items-grid">
                {videoProductionItems.map(item => (
                    <ItemCard key={item.itemId} item={item} />
                ))}
            </div>
        </div>
    );
};

export default AccessoryPage;


