//app.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Profile from './pages/Profile';
import AccessoryPage from './pages/AccessoryPage';
import AudioEquipmentPage from './pages/AudioEquipmentPage';
import CameraPage from './pages/CameraPage';
import LensPage from './pages/LensPage';
import LightingPage from './pages/LightingPage';
import ProjectorPage from './pages/ProjectorPage';
import TripodPage from './pages/TripodPage';
import UsedItemPage from './pages/UsedItemPage';
import Footer from './components/common/Footer';
import PrivateRoute from './components/common/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import Header from './components/common/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar } from 'react-bootstrap';
import AllItemsPage from './pages/AllItemsPage';
import ItemDetailPage from './pages/ItemDetailPage';
import AdminItemsPage from "./pages/AdminItemsPage";
import AdminPricingPage from "./pages/AdminPricingPage";
import 'react-datepicker/dist/react-datepicker.css';
import { Outlet, Link } from 'react-router-dom';
import Unauthorized from "./pages/Unauthorized";


const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Header />
                <Navbar bg="dark" variant="dark">
                    <Navbar.Brand href="/">Rent Ranger</Navbar.Brand>
                </Navbar>
                <Container>
                    <Routes>
                        {/* Public Routes */}
                        <Route path="/" element={<Home />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/all-items" element={<AllItemsPage />} />
                        <Route path="/items/:itemId" element={<ItemDetailPage />} />
                        <Route path="/unauthorized" element={<Unauthorized />} />


                        {/* Admin Routes */}
                        <Route
                            path="/admin/*"
                            element={
                                <PrivateRoute requiredRole="ROLE_ADMIN">
                                    <AdminLayout />
                                </PrivateRoute>
                            }
                        >
                            <Route path="items" element={<AdminItemsPage />} />
                            <Route path="items/:itemId/pricing" element={<AdminPricingPage />} />
                            {/* Add more admin routes as needed */}
                        </Route>

                        {/* Private Routes */}
                        <Route path="/profile" element={
                            <PrivateRoute>
                                <Profile />
                            </PrivateRoute>
                        } />
                        <Route path="/accessories" element={
                            <PrivateRoute>
                                <AccessoryPage />
                            </PrivateRoute>
                        } />
                        <Route path="/audioequipments" element={
                            <PrivateRoute>
                                <AudioEquipmentPage />
                            </PrivateRoute>
                        } />
                        <Route path="/cameras" element={
                            <PrivateRoute>
                                <CameraPage />
                            </PrivateRoute>
                        } />
                        <Route path="/lenses" element={
                            <PrivateRoute>
                                <LensPage />
                            </PrivateRoute>
                        } />
                        <Route path="/lightings" element={
                            <PrivateRoute>
                                <LightingPage />
                            </PrivateRoute>
                        } />
                        <Route path="/projectors" element={
                            <PrivateRoute>
                                <ProjectorPage />
                            </PrivateRoute>
                        } />

                        <Route path="/tripods" element={
                            <PrivateRoute>
                                <TripodPage />
                            </PrivateRoute>
                        } />
                        <Route path="/useditems" element={
                            <PrivateRoute>
                                <UsedItemPage />
                            </PrivateRoute>
                        } />
                    </Routes>
                </Container>
                <Footer className="bg-dark text-white text-center py-3">© 2024 Rent Ranger</Footer>
            </Router>
        </AuthProvider>
    );
};

export default App;

const AdminLayout = () => (
    <div>
        <h1>Admin Dashboard</h1>
        {/* Admin Navigation Links */}
        <nav>
            <ul>
                <li>
                    <Link to="items">Manage Items</Link>
                </li>
                {/* Add more admin links as needed */}
            </ul>
        </nav>
        <Outlet />
    </div>
);