import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

const PrivateRoute = ({ children, requiredRole }) => {
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>; // Or some spinner
    }

    if (!user) {
        return <Navigate to="/login" />;
    }

    console.log('Required role:', requiredRole);
    console.log('User roles:', user.roles);

    if (requiredRole && !user.roles.includes(requiredRole)) {
        return <Navigate to="/unauthorized" />;
    }

    return children;
};

export default PrivateRoute;
