import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getAllTripods = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const getTripodById = async (id) => {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
};

export const addTripod = async (tripod) => {
    const response = await axios.post(API_URL, tripod);
    return response.data;
};

export const updateTripod = async (id, tripod) => {
    const response = await axios.put(`${API_URL}/${id}`, tripod);
    return response.data;
};

export const deleteTripod = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};

export const getAvailableTripods = async () => {
    const response = await axios.get(`${API_URL}/available`);
    return response.data;
};
