// src/pages/ItemDetailPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import api from '../api';
import './ItemDetailPage.css';
import { parseISO, set } from 'date-fns';

const ItemDetailPage = () => {
    const { itemId } = useParams(); // Ensure itemId is extracted from URL
    const [item, setItem] = useState(null); // State for the item
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [price, setPrice] = useState(0);

    // Fetch store hours, holidays, pricing tiers, and bookings
    const [storeHours, setStoreHours] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [pricingTiers, setPricingTiers] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(true);
    const timeZone = 'America/Chicago'; // CST

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch item
                const itemResponse = await api.get(`/items/${itemId}`);
                setItem(itemResponse.data);

                // Fetch store hours
                const storeHoursResponse = await api.get('/store/hours');
                setStoreHours(storeHoursResponse.data);

                // Fetch holidays
                const holidaysResponse = await api.get('/store/holidays');
                setHolidays(holidaysResponse.data);

                // Fetch pricing tiers
                const pricingResponse = await api.get(`/pricing/item/${itemId}`);
                setPricingTiers(pricingResponse.data);

                // Fetch bookings
                const bookingsResponse = await api.get(`/rentals/availability/${itemId}`);
                setBookings(bookingsResponse.data);

                setLoading(false);
            } catch (err) {
                setError('Failed to fetch data');
                setLoading(false);
                console.error(err);
            }
        };

        fetchData();
    }, [itemId]);

    const setToMidnight = (date) => {
        return set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    };

    const isDateDisabled = (date) => {
        const currentDate = setToMidnight(date);

        // Disable holidays
        const isHoliday = holidays.some((holiday) => {
            const holidayDate = setToMidnight(parseISO(holiday.date));
            return currentDate.getTime() === holidayDate.getTime();
        });

        // Disable store closed days
        const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        const dayName = daysOfWeek[dayOfWeek];

        const storeHour = storeHours.find((sh) => sh.dayOfWeek === dayName);
        const isStoreClosed = storeHour ? !storeHour.isOpen : true;

        // Disable dates with existing bookings
        const isBooked = bookings.some((booking) => {
            const bookingStart = setToMidnight(parseISO(booking.startDate));
            const bookingEnd = setToMidnight(parseISO(booking.endDate));

            // Include the entire end date
            return currentDate >= bookingStart && currentDate <= bookingEnd;
        });

        return isHoliday || isStoreClosed || isBooked;
    };

    // Function to handle booking
    const handleBooking = async () => {
        const booking = {
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
            item: { itemId: item.itemId },
            customerName,
            customerEmail,
        };

        try {
            await api.post('/rentals/book', booking);
            alert('Booking successful!');
            // Reset form and booking details
            setCustomerName('');
            setCustomerEmail('');
            setStartDate(null);
            setEndDate(null);
            setShowConfirmation(false);
            // Refresh bookings to update availability
            const response = await api.get(`/rentals/availability/${itemId}`);
            setBookings(response.data);
        } catch (error) {
            alert('Failed to create booking.');
            console.error(error);
        }
    };

    // Function to handle date range change and calculate price
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end && item) {
            // Calculate the difference in days
            const nights = Math.round((end - start) / (1000 * 60 * 60 * 24));

            // If nights is less than 1, set it to 1
            const rentalNights = nights < 1 ? 1 : nights;

            // Calculate price based on pricing tiers
            const sortedPricingTiers = [...pricingTiers].sort((a, b) => a.minNights - b.minNights);

            const applicableTier = sortedPricingTiers.find((tier) => {
                return (
                    rentalNights >= tier.minNights &&
                    (tier.maxNights == null || rentalNights <= tier.maxNights)
                );
            });

            if (applicableTier) {
                setPrice(applicableTier.pricePerNight * rentalNights);
            } else {
                // Default pricing if no tier matches
                setPrice(item.price * rentalNights);
            }
        } else {
            setPrice(0);
        }
    };


    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (!item) return <p>Item not found</p>;

    return (
        <div className="item-detail-container">
            {/* Left column: Image */}
            <div className="item-detail-left">
                {item.imageUrl ? (
                    <img src={item.imageUrl} alt={item.description} className="item-detail-image" />
                ) : (
                    <div className="item-detail-placeholder">No Image Available</div>
                )}
            </div>

            {/* Center column: Description and information */}
            <div className="item-detail-center">
                <h1 className="item-detail-title">{item.description}</h1>
                <div className="item-detail-info">
                    <p>
                        <strong>SKU:</strong> {item.customSku}
                    </p>
                    <p>
                        <strong>Price:</strong> ${item.price.toFixed(2)} / per night
                    </p>
                    <p>
                        <strong>Available:</strong> {item.quantityOnHand}
                    </p>
                    <p>
                        <strong>Details:</strong> {item.note}
                    </p>
                </div>
            </div>

            {/* Right column: Date picker and price calculation */}
            <div className="item-detail-right">
                <h2>Select Rental Dates</h2>
                <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    minDate={new Date()}
                    filterDate={(date) => !isDateDisabled(date)}
                />
                {startDate && endDate && (
                    <div className="rental-price">
                        <p>
                            <strong>Total Price:</strong> ${price.toFixed(2)}
                        </p>
                        <button onClick={() => setShowConfirmation(true)}>Proceed to Booking</button>
                    </div>
                )}

                {showConfirmation && (
                    <div className="confirmation-form">
                        <h2>Enter Your Details</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleBooking();
                            }}
                        >
                            <label>
                                Name:
                                <input
                                    type="text"
                                    value={customerName}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    required
                                />
                            </label>
                            <br />
                            <label>
                                Email:
                                <input
                                    type="email"
                                    value={customerEmail}
                                    onChange={(e) => setCustomerEmail(e.target.value)}
                                    required
                                />
                            </label>
                            <br />
                            <button type="submit">Confirm Booking</button>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ItemDetailPage;